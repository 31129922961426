div.sliderContainer {
  width: 100%;
  height: 100%;
  border-top: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-left: 1px solid #ccc;

  div.slidesContainer {
    div.slideWrapper {
      position: relative;
    }
    img {
      height: 400px;
      object-fit: cover;
    }
    .slideDisplayNone {
      display: none;
    }
    div.deleteBtnWrapper {
      position: absolute;
      cursor: pointer;
      right: 0;
      margin-top: -30px;
      border: 1px solid red;
      width: 25px;
      height: 25px;
      background-color: red;
      display: flex;
      justify-content: center;
      border-radius: 10px;
      z-index: 1;

      &:hover {
        background-color: red;
        border-color: red;
      }

      .deleteBtn {
        color: #fff;
      }
    }
  }

  div.paginationContainer {
    border-top: 1px solid #aaa;
    border-bottom: 1px solid #ccc;
    display: flex;
    justify-content: space-between;
    padding: 15px 15px;
    align-items: center;

    div.dotContainer {
      display: flex;
      align-items: center;

      .sliderIndexDot {
        &.selectedSliderIndexDot {
          font-size: 25px;
          color: rgb(0, 102, 255);
        }
      }
    }

    .paginationIcon {
      font-size: 25px;
      cursor: pointer;
      color: rgb(0, 102, 255);

      &.disabledArrow {
        color: #ccc;
        cursor: initial;
      }
    }
  }
}

.notAvailableImage {
  height: 400px;
  object-fit: cover;
}
