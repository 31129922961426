.values {
  color: var(--neutral-background-text-black-300, #404040) !important;

  /* M3/Body/Medium */
  font-family: Roboto !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 20px !important; /* 142.857% */
  letter-spacing: 0.25px !important;
  background-color: white !important;
}

.slab-table .ant-table-selection-column {
  background-color: white !important;
}

.slab-table .ant-table-thead > tr > th {
  color: var(--neutral-background-text-black-300, #404040) !important;

  /* M3/Label/Large */
  font-family: Roboto !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 20px !important; /* 142.857% */
  letter-spacing: 0.1px !important;
  background: var(--primary-blue-50, #f0f1fe) !important;
}

.slab-table .ant-table-thead > tr > td {
  background-color: rgba(238, 244, 253, 0.8);
}
