.ListViewProducts {
  width: 83vw;
  overflow-y: auto;
  height: 63vh;
  padding-right: 1vw;
}

.ListViewItem {
  border-radius: 8px;
  border-bottom: 0.75px solid var(--neutral-background-text-white-300, #e6e6e6);
  background: var(--neutral-background-text-white-100, #f9f9f9);
  margin-bottom: 2vh !important;
}

#productName1 {
  color: #000 !important;
  /* margin-left: -0.3vw; */
  /* M3/Body/Large */
  font-family: Roboto !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 24px !important; /* 150% */
  letter-spacing: 0.5px !important;
}

.otherDetails1 {
  color: #000;
  /* text-align: center; */

  /* M3/Body/Large */
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: 0.5px;
}

.tableHeader1 {
  color: var(--neutral-background-text-black-400, #2d2d2d) !important;
  text-align: center !important;

  /* M3/Label/Large */
  font-family: Roboto !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  /* line-height: 20px !important; 142.857% */
  letter-spacing: 0.1px !important;
  margin-bottom: 0;
}

.collapsePanel .ant-collapse-content-box {
  background-color: white !important;
  padding: 0 !important;
}

.productTable .ant-table-thead {
  background: var(--primary-blue-50, #f0f1fe);
}

.productTable .ant-table-thead .ant-table-cell {
  background: var(--primary-blue-50, #f0f1fe) !important;
  color: var(--neutral-background-text-black-300, #404040) !important;

  /* M3/Label/Large */
  font-family: Roboto !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 20px !important; /* 142.857% */
  letter-spacing: 0.1px !important;
}

.productTable .ant-table-cell {
  background-color: white;
  color: var(--neutral-background-text-black-300, #404040);

  /* M3/Body/Medium */
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.25px;
}

.ListViewProducts::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

.ListViewProducts::-webkit-scrollbar-track {
  background-color: #f0f0f0; /* Grey shade for track */
}

.ListViewProducts::-webkit-scrollbar-thumb {
  background-color: #0c25f3; /* Blue color for thumb */
  border-radius: 5px; /* Rounded corners for thumb */
}

/* On hover, adjust the thumb color */
.ListViewProducts::-webkit-scrollbar-thumb:hover {
  background-color: #0c25f3; /* Darker blue on hover */
}

/* On active (click and drag), adjust the thumb color */
.ListViewProducts::-webkit-scrollbar-thumb:active {
  background-color: #0c25f3; /* Even darker blue on active */
}

.ant-modal-footer .ant-btn-primary {
  background: var(--m-3-sys-light-primary, #2c3fff);
  color: var(--m-3-sys-light-on-primary, #fff);
  text-align: center;

  /* M3/label/large */
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.1px;
}
