.companyProfileContainer {
  background-color: #f0f0eb;
  padding: 4vh 6vw;
}

.companyProfileDetailsContainer {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 2vh;
}

.companyProfileKey {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 100%;
  /* identical to box height, or 16px */

  letter-spacing: 0.005em;

  color: #3077ff;
}

.companyProfileValue {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;
  /* identical to box height, or 16px */

  letter-spacing: 0.005em;

  color: #3f3f3f;
}

.MyProfileHeader {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 100%;
  /* identical to box height, or 16px */

  letter-spacing: 0.005em;
}

.ProfileRow {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.profileHeaders {
  color: var(--neutral-background-text-black-100, #666);

  /* M3/Title/Medium */
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  letter-spacing: 0.15px;
}

.profileValues {
  color: var(--neutral-background-text-black-300, #404040);

  /* M3/Title/Medium */
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  letter-spacing: 0.15px;
}

.subHeaderProfile {
  margin-top: 3vh;
  margin-bottom: 3vh;
  color: var(--neutral-background-text-black-100, #666);
  text-align: center;

  /* Subheading/Semi Bold */
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 120% */
}
