.list-headers-style {
  width: "68vw";
  display: "flex";
  justify-content: "space-between";
  align-items: "center";
  padding-left: "18px";
  color: #a2a3a5;
}

.borderless-button {
  border: none;
}

.inventoryContent::-webkit-scrollbar {
  width: 0px;
  background-color: transparent;
}

.imagePopUp .ant-card-body {
  padding: 0;
  background-color: #1f7eff;
}

.bundle-values {
  color: black;
}

.bundle-table .ant-table-thead .ant-table-cell {
  color: black !important;
}

.values {
  color: black !important;
}

.form4Header {
  color: rgba(57, 57, 57, 0.66);
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.14px;
}

.addImageTable .ant-collapse-content-box {
  padding: 0 !important;
}

.UploadDescription {
  color: #a2a3a5;
  font-family: Open Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: 0.09px;
}

.imageUploadHeading {
  color: #06f;
  font-family: Open Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: 0.09px;
}

.deleteIconCls {
  color: red;
  font-size: medium;
}

.deleteIconCls:hover {
  color: black;
}

.cloudUploadCls {
  color: #017fff;
  font-size: large;
}

.cloudUploadCls:hover {
  color: black;
}

.offerOptionCard {
  position: fixed;
  width: 150px;
  bottom: 74px;
  right: 64px;
  box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15),
    0px 1px 2px 0px rgba(0, 0, 0, 0.3);
}

.offerOptionCard > div.ant-card-body {
  padding: 20px 0px;
}

.offerOptionCard button {
  width: 100%;
}
