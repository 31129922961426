.supportHeader {
  padding: 2vh 0;
  color: var(--neutral-background-text-black-300, #404040);
  text-align: center;

  /* Subheading/Semi Bold */
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 120% */
}
