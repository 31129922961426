.offerTable .ant-table-thead .ant-table-cell {
  background-color: #0066ff !important;
  color: var(--neutral-background-text-black-300, white) !important;

  /* M3/Label/Large */
  font-family: Roboto !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 20px !important; /* 142.857% */
  letter-spacing: 0.1px !important;
  /* background: var(--primary-blue-50, #f0f1fe) !important; */
}

.offerTable .ant-table-tbody .ant-table-row {
  color: var(--neutral-background-text-black-300, #404040) !important;

  /* M3/Body/Medium */
  font-family: Roboto !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 20px !important; /* 142.857% */
  letter-spacing: 0.25px !important;
  background-color: white !important;
}

.drawerTitle1 {
  color: var(--neutral-background-text-black-300, #404040);

  /* M3/Body/Large */
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400; /* 150% */
  letter-spacing: 0.5px;
}

.drawerTitle1.ant-breadcrumb ol {
  align-items: center;
}
