.companyProfileContainer {
  background-color: #f0f0eb;
  padding: 4vh 6vw;
}

.companyProfileDetailsContainer {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 2vh;
}

.companyProfileKey {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 100%;
  /* identical to box height, or 16px */

  letter-spacing: 0.005em;

  color: #3077ff;
}

.companyProfileValue {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;
  /* identical to box height, or 16px */

  letter-spacing: 0.005em;

  color: #3f3f3f;
}

/* NEW */
.mainCompanyHeading {
  padding: 2vh 0;
  color: var(--neutral-background-text-black-300, #404040);
  text-align: center;

  /* Subheading/Semi Bold */
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 120% */
}

.companyEditButtonDiv {
  display: flex;
  align-items: center;
  justify-content: right;
  padding-right: 4vw;
}

.companyEditButton {
  color: var(--primary-blue-500, #0c25f3);
  text-align: center;
  border-color: var(--primary-blue-500, #0c25f3);
  /* M3/Label/Large */
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.1px;
}

.companyEditButton:hover {
  color: var(--m-3-sys-light-on-primary, #fff) !important;
  text-align: center;

  /* M3/label/large */
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.1px;
  /* border-radius: 100px; */
  background: var(--m-3-sys-light-primary, #2c3fff) !important;
}
