.ListView {
  width: 82vw !important;
  overflow-y: auto;
  height: 58vh !important;
  padding-right: 1vw;
}

.ListViewItem {
  border-radius: 8px !important;
  border-bottom: 0.75px solid var(--neutral-background-text-white-300, #e6e6e6) !important;
  background: var(--neutral-background-text-white-100, #f9f9f9) !important;
  margin-bottom: 2vh !important;
}

.nextButton {
  color: var(--neutral-background-text-white-000, #fff);
  text-align: center;

  /* M3/Label/Large */
  font-family: Roboto !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 20px !important; /* 142.857% */
  letter-spacing: 0.1px !important;
}

.HeaderBundleSlab {
  color: var(--neutral-background-text-black-300, #404040);

  /* M3/body/large */
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: 0.5px;
}

.AddSlabBundle {
  color: var(--primary-blue-500, #0c25f3) !important;
  text-align: center;

  /* M3/Label/Large */
  font-family: Roboto !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 20px !important; /* 142.857% */
  letter-spacing: 0.1px !important;
  text-transform: none !important;
}

.SelectSort {
  color: var(--neutral-background-text-black-300, #404040);

  /* M3/Label/Large */
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.1px;
  width: 10vw;
}

.slabAutocomplete
  .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-1h51icj-MuiAutocomplete-root
  .MuiOutlinedInput-root {
  padding-right: 0 !important;
}

.slabAutocomplete .MuiInputBase-root {
  padding-right: 30px !important;
}

.slabAutocomplete .MuiInputBase-root input {
  font-size: 13px;
  padding: 14px;
}

.slabTextInput input {
  font-size: 13px;
  padding: 8px;
}

.slabTextInput .MuiInputBase-root {
  flex: 1;
}

.addButton {
  border-radius: 16px;
  background: var(--m-3-sys-light-surface-container-high, #e6e8e9);
  color: #0c25f3;
  /* M3/Elevation Light/3 */
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3),
    0px 4px 8px 3px rgba(0, 0, 0, 0.15);
}

.addButton:hover {
  background: var(--m-3-sys-light-primary, #2c3fff) !important;
  color: var(--m-3-sys-light-on-primary, #fff) !important;
}

.sortHeading {
  color: #000;

  /* M3/Label/Large */
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.1px;
}

#productName {
  color: #000;
  text-align: center;

  /* M3/Body/Large */
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: 0.5px;
}

.otherDetails {
  color: #000;
  text-align: center;

  /* M3/Body/Large */
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: 0.5px;
}

.inventoryButton {
  /* color: var(--neutral-background-text-black-300, #404040); */
  text-align: center;

  /* M3/Label/Large */
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.1px;
}

.tableHeader2 {
  color: var(--neutral-background-text-black-400, #2d2d2d) !important;
  /* text-align: center !important; */

  /* M3/Label/Large */
  font-family: Roboto !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  /* line-height: 20px !important; 142.857% */
  letter-spacing: 0.1px !important;
  margin-bottom: 0;
}

/* ScrollBar */
/* Define scrollbar track and thumb styles */
.ListView::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

.ListView::-webkit-scrollbar-track {
  background-color: #f0f0f0; /* Grey shade for track */
}

.ListView::-webkit-scrollbar-thumb {
  background-color: #0c25f3; /* Blue color for thumb */
  border-radius: 5px; /* Rounded corners for thumb */
}

/* On hover, adjust the thumb color */
.ListView::-webkit-scrollbar-thumb:hover {
  background-color: #0c25f3; /* Darker blue on hover */
}

/* On active (click and drag), adjust the thumb color */
.ListView::-webkit-scrollbar-thumb:active {
  background-color: #0c25f3; /* Even darker blue on active */
}

.ListViewInner {
  width: 100% !important;
  overflow-y: auto;
  height: 70vh;
  padding-right: 1vw;
}

.InnerCollapse {
  /* padding-top: 1vh; */
  /* padding-bottom: 1vh; */
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-radius: 8px;
  border-bottom: 0.75px solid var(--neutral-background-text-white-300, #e6e6e6) !important;
  background: var(--neutral-background-text-white-100, #f9f9f9) !important;
}

.InnerCollapse .ant-collapse-content-box {
  background-color: white !important;
  padding: 0 !important;
}

.InnerCollapse .ant-collapse-arrow {
  color: #0c25f3 !important;
}

.bundleList {
  color: var(--neutral-background-text-black-300, #404040);

  /* M3/Body/Large */
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: 0.5px;
}

.imageHeadingInventory {
  color: var(--neutral-background-text-black-300, #404040);

  /* M3/Body/Large */
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  letter-spacing: 0.5px;
}

.drawerTitle {
  color: var(--neutral-background-text-black-300, #404040);

  /* M3/Body/Large */
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: 0.5px;
}

.ListViewInner::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

.ListViewInner::-webkit-scrollbar-track {
  background-color: #f0f0f0; /* Grey shade for track */
}

.ListViewInner::-webkit-scrollbar-thumb {
  background-color: #0c25f3; /* Blue color for thumb */
  border-radius: 5px; /* Rounded corners for thumb */
}

/* On hover, adjust the thumb color */
.ListViewInner::-webkit-scrollbar-thumb:hover {
  background-color: #0c25f3; /* Darker blue on hover */
}

/* On active (click and drag), adjust the thumb color */
.ListViewInner::-webkit-scrollbar-thumb:active {
  background-color: #0c25f3; /* Even darker blue on active */
}

.ListViewCart {
  overflow-y: auto;
  height: 54vh;
  padding-right: 1vw;
}

.ListViewCart::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

.ListViewCart::-webkit-scrollbar-track {
  background-color: #f0f0f0; /* Grey shade for track */
}

.ListViewCart::-webkit-scrollbar-thumb {
  background-color: #0c25f3; /* Blue color for thumb */
  border-radius: 5px; /* Rounded corners for thumb */
}

/* On hover, adjust the thumb color */
.ListViewCart::-webkit-scrollbar-thumb:hover {
  background-color: #0c25f3; /* Darker blue on hover */
}

/* On active (click and drag), adjust the thumb color */
.ListViewCart::-webkit-scrollbar-thumb:active {
  background-color: #0c25f3; /* Even darker blue on active */
}

.values {
  color: var(--neutral-background-text-black-300, #404040) !important;

  /* M3/Body/Medium */
  font-family: Roboto !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 20px !important; /* 142.857% */
  letter-spacing: 0.25px !important;
  background-color: white !important;
}

.slab-table .ant-table-selection-column {
  background-color: white !important;
}

.slab-table .ant-table-thead > tr > th {
  color: var(--neutral-background-text-black-300, #404040) !important;

  /* M3/Label/Large */
  font-family: Roboto !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 20px !important; /* 142.857% */
  letter-spacing: 0.1px !important;
  background: var(--primary-blue-50, #f0f1fe) !important;
}

.inventory-table .ant-table-thead > tr > th {
  color: var(--neutral-background-text-black-300, #404040) !important;
  text-align: center !important;
  /* M3/Label/Large */
  font-family: Roboto !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 20px !important; /* 142.857% */
  letter-spacing: 0.1px !important;
  background: var(--primary-blue-50, #f0f1fe) !important;
}
