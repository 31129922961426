.modalForm {
  .ant-modal-content {
    border-radius: 24px;
  }
  .ant-space {
    width: 100%;
  }
  .ant-space-item {
    width: 100%;
  }
  .ant-collapse {
    .ant-collapse-content {
      border-top: none;
    }
  }
  .ant-modal-footer {
    text-align: center;
  }
}
.ant-select-borderless {
  .ant-select-selector {
    border-bottom: 1px solid #d9d9d9 !important;
    border-radius: 0 !important;
    padding-left: 0 !important;
  }
}
.ant-input-borderless,
.ant-input-number-borderless {
  border-bottom: 1px solid #d9d9d9 !important;
  border-radius: 0 !important;
  padding-left: 0 !important;
  .ant-input-number-input {
    padding-left: 0 !important;
  }
}
