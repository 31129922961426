div.offerPageContainer {
  padding: 3vh 3vw;
  width: 100%;

  &.offerPageContainerCancelled {
    > div {
      border-radius: 10px;
      border: 1px solid #bbb;
      padding: 3vh 3vw;
      display: flex;
      flex-direction: column;

      div.iconContainer {
        display: flex;
        justify-content: center;
      }

      div.userMessage {
        margin-top: 20px;

        h1 {
          text-align: center;
          font-size: 30px;
        }
      }
    }
  }

  div.productInfoList {
    margin-top: 20px;
  }
}
