.offersTable table:first-of-type {
  thead > tr > th,
  thead > tr > td {
    color: #404040;
    background: #fff;
    border-bottom: none;
    text-align: center;
  }

  tbody > tr,
  thead > tr {
    background-color: #f9f9f9;
    color: #404040;

    td {
      border-top: 5px solid #fff;
      border-bottom: 5px solid #fff;
      text-align: center;
    }
  }

  thead,
  tbody > tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }

  tbody {
    max-height: 61vh;
    overflow-y: scroll;
    display: block;

    /* ScrollBar */
    /* Define scrollbar track and thumb styles */
    &::-webkit-scrollbar {
      width: 8px; /* Width of the scrollbar */
    }

    &::-webkit-scrollbar-track {
      background-color: #f0f0f0; /* Grey shade for track */
    }

    &::-webkit-scrollbar-thumb {
      background-color: #0c25f3; /* Blue color for thumb */
      border-radius: 5px; /* Rounded corners for thumb */
    }

    /* On hover, adjust the thumb color */
    &::-webkit-scrollbar-thumb:hover {
      background-color: #0c25f3; /* Darker blue on hover */
    }

    /* On active (click and drag), adjust the thumb color */
    &::-webkit-scrollbar-thumb:active {
      background-color: #0c25f3; /* Even darker blue on active */
    }
  }
}

section.offersLayout,
div.offersLayout {
  :global(aside.ant-layout-sider) {
    border-right: 1px solid #ccc;
    border-radius: 0px;
  }
}
