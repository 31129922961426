.ant-collapse {
  border-radius: 0;
}

.bundle-card .ant-collapse-header {
  border: 1px solid #d3d3d3;
}

.collapsePanel .ant-collapse-header {
  padding: 14px 24px !important;
  // border-radius: 0;
  // background-color: white !important;
  background: var(--neutral-background-text-white-100, #f9f9f9) !important;
}

.collapsePanel .ant-collapse-item {
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  border-radius: 8px !important;
  border-bottom: 0.75px solid var(--neutral-background-text-white-300, #e6e6e6) !important;
  background: var(--neutral-background-text-white-100, #f9f9f9) !important;
  // margin-bottom: 2vh !important;
}
