.HomeLayout {
  height: 83vh;
  background-image: url("https://images.unsplash.com/photo-1534947379496-bedc63409670?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=774&q=80");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.5);
}
.HomeContent {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
}
.ant-layout-content {
  margin: 0 !important;
  width: 100vw;
}

.ant-layout-footer {
  background-color: white !important;
}

.greetContent {
  display: flex;
  align-items: center;
  justify-content: center;
}

.greetText {
  color: #fff;

  /* Display/01/Bold */
  font-family: Montserrat;
  font-size: 72px;
  font-style: normal;
  font-weight: 700;
  line-height: 80px; /* 111.111% */
  letter-spacing: -2.88px;
}

.quoteTxt {
  color: #fff;

  /* Heading/H1/Semi Bold */
  font-family: Montserrat;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 56px; /* 116.667% */
  letter-spacing: -1.92px;
}

.subQuote {
  color: #fff;

  /* Heading/H5 - Small/Semi Bold */
  font-family: Montserrat;
  font-size: 19px;
  font-style: normal;
  font-weight: 600;
  line-height: 23px; /* 121.053% */
  letter-spacing: -0.38px;
}

.author {
  color: #fff;

  /* Heading/H6 - Small/Semi Bold */
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 19px; /* 118.75% */
  letter-spacing: -0.32px;
}

.AnalyticsMainHeader {
  text-align: center;
  font-size: 30px;
  font-style: normal;
  font-weight: 2000;
  font-family: Montserrat;
  /* line-height: 19px; 118.75% */
  letter-spacing: -0.32px;
  background: linear-gradient(
    90deg,
    #000,
    #333,
    #666
  ); /* Gradient background from black to dark grey */
  -webkit-background-clip: text; /* Clip text to the gradient background */
  background-clip: text;
  color: transparent; /* Make the text transparent to show the background gradient */
  display: inline-block; /* Ensure the background gradient applies only to the text content */
}

.subHeaderAnalytics {
  color: #676767;
  margin-bottom: 0;
  /* Heading/H5 - Small/Semi Bold */
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 23px; /* 121.053% */
  letter-spacing: -0.38px;
}

.counterClass {
  font-size: 65px;
  font-style: normal;
  font-weight: 2000;
  font-family: Montserrat;
  letter-spacing: -0.32px;
}

.chartBody {
  padding: 2vh 2vw;
  border-radius: 12px;
  background-color: rgba(164, 164, 164, 0.1);
  transition: box-shadow 0.3s;
  box-shadow: 0 0 2px 5px rgba(203, 203, 203, 0.5);
}
.chartBody:hover {
  box-shadow: 0 0 10px 5px rgba(128, 128, 128, 0.5);
}
