div.slabTable {
  table {
    width: 2500px !important;
    table-layout: fixed !important;
    td,
    th {
      text-align: center !important;
    }
    td {
      padding: 10px !important;
    }

    th {
      background-color: rgba(238, 244, 253, 0.8) !important;
    }
  }

  .textField {
    div.MuiInputBase-root {
      padding-right: 10px;
    }
  }
}
