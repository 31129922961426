.values {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.005em;
  color: #a2a3a5;
  background-color: rgba(238, 244, 253, 0.8);
}

.slab-table .ant-table-thead > tr > th {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700 !important;
  font-size: 12px;
  letter-spacing: 0.005em;
  color: #a2a3a5;
  background-color: rgba(238, 244, 253, 0.8);
}

.slab-table .ant-table-thead > tr > td {
  background-color: rgba(238, 244, 253, 0.8);
}
