.ant-tabs-nav-wrap {
  background: var(--m-3-sys-light-surface, #f7faf8);
  border-top: 2px solid #f1f1ef;
  /* padding: 1vh 0; */
  height: 7vh;
}
/* @media (max-height: 4vh) {
    .ant-tabs-nav-wrap {
      display: none !important;
    }
    .ant-tabs-nav {
        display: none !important;
      }
  } */

.ant-tooltip-inner {
  background-color: #2c3fff !important;
}

.ant-tooltip-arrow {
  display: none !important;
}

.ant-tabs-nav-wrap:empty {
  height: 0;
}
.ant-tabs-nav:empty {
  height: 0;
}

.ant-tabs-nav {
  width: 100vw !important;
}

.ant-tabs-tab-btn {
  text-align: center !important;

  /* M3/Label/Large */
  font-family: Roboto !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 20px !important; /* 142.857% */
  letter-spacing: 0.1px !important;
}

.ant-tabs-tab-active {
  color: var(--primary-blue-500, #0c25f3) !important;
}

.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--primary-blue-500, #0c25f3) !important;
}

.ant-tabs-ink-bar {
  background: var(--primary-blue-500, #0c25f3) !important;
}

.upgradeBtn:hover {
  background-color: white !important;
  color: #2c3fff !important;
  border: 1px solid #2c3fff !important;
}
