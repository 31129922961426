.ListViewSales {
  width: 83vw !important;
  overflow-y: auto;
  height: 50vh;
  padding-right: 1vw;
}

.salesTableSales .ant-table-thead .ant-table-cell {
  background: var(--primary-blue-50, #f0f1fe) !important;
  color: var(--neutral-background-text-black-300, #404040) !important;
  text-align: center !important;

  /* M3/Label/Large */
  font-family: Roboto !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 20px !important; /* 142.857% */
  letter-spacing: 0.1px !important;
}

.salesTableSales .ant-table-tbody .ant-table-row {
  border-bottom: 0.75px solid var(--neutral-background-text-white-300, #e6e6e6) !important;
  background: var(--neutral-background-text-white-000, #fff) !important;
  color: var(--neutral-background-text-black-300, #404040) !important;
  text-align: center !important;

  /* M3/Body/Medium */
  font-family: Roboto !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 20px !important; /* 142.857% */
  letter-spacing: 0.25px !important;
}

.tableSummary {
  border-bottom: 0.75px solid var(--neutral-background-text-white-100, #f9f9f9) !important;
  background: var(--neutral-background-text-white-100, #f9f9f9) !important;
  color: var(--neutral-background-text-black-300, #404040) !important;
  text-align: center !important;

  /* M3/Body/Medium */
  font-family: Roboto !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 20px !important; /* 142.857% */
  letter-spacing: 0.25px !important;
}

.smallHeader {
  color: var(--neutral-background-text-black-100, #666);

  /* M3/Body/Medium */
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.25px;
}

.mediumHeader {
  color: var(--neutral-background-text-black-300, #404040);

  /* M3/Body/Large */
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: 0.5px;
}

.SalesCollapseButton {
  color: var(--primary-blue-500, #0c25f3);

  /* M3/Label/Large */
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.1px;
}

.submitBtn {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
}

.blackHeading {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  /* identical to box height, or 100% */

  letter-spacing: 0.1px;

  color: #11142d;
  margin-bottom: 2vh;
}

.salesHeaderList {
  color: var(--neutral-background-text-black-400, #2d2d2d);

  /* M3/Label/Large */
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.1px;
}

.mainCollapseSales {
  color: var(--neutral-background-text-black-300, #404040);

  /* M3/Body/Large */
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: 0.5px;
}

.moveToHeader {
  color: var(--neutral-background-text-black-300, #404040);

  /* M3/Body/Large */
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: 0.5px;
}

.MoveToCard {
  border-radius: 4px 4px 0px 0px;
}

.MoveToCard .ant-collapse-header {
  border-radius: 10px !important;
  box-shadow: inset 0 0 3px black;
  background-color: white !important;
}

.MoveToCard .ant-collapse-content-box {
  border-radius: 4px !important;
  background: var(--primary-blue-50, #f0f1fe) !important;
}

.radioButtonSales {
  color: var(--neutral-background-text-black-300, #404040) !important;

  /* M3/Body/Medium */
  font-family: Roboto !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 20px !important; /* 142.857% */
  letter-spacing: 0.25px !important;
}

.submitBtnSales {
  background: var(--m-3-sys-light-primary, #2c3fff);
  color: var(--m-3-sys-light-on-primary, #fff);
  text-align: center;

  /* M3/label/large */
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.1px;
}

.drawerTitleSales {
  color: var(--neutral-background-text-black-300, #404040);

  /* M3/Body/Large */
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: 0.5px;
}

.notesHeader {
  color: var(--neutral-background-text-black-300, #404040);

  /* Paragraph/P3/Semi Bold */
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 21.6px; /* 120% */
}

.TextAreaNotes {
  color: var(--neutral-background-text-black-300, #404040);

  /* M3/Body/Large */
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: 0.5px;
}

.saveBtn {
  background: var(--m-3-sys-light-primary, #2c3fff);
  color: var(--m-3-sys-light-on-primary, #fff);
  text-align: center;

  /* M3/label/large */
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.1px;
}

.notesSales {
  border-radius: 4px;
  border: 1px solid var(--neutral-background-text-white-500, #c6c6c6);
}

.notesUsername {
  color: var(--neutral-background-text-black-300, #404040);

  /* M3/Body/Medium */
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.25px;
}

.notesData {
  color: var(--neutral-background-text-black-300, #404040);

  /* M3/Body/Large */
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: 0.5px;
}

.notesDateTime {
  color: var(--neutral-background-text-black-300, #404040);

  /* M3/Body/Medium */
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.25px;
}

.SalesProductCollapse .ant-collapse-header {
  border-radius: 0 !important;
  border-top: 0;
  border-right: 0;
  border-left: 0;
  background-color: white !important;
  border-bottom: 0.75px solid var(--neutral-background-text-white-500, #c6c6c6);
}

.SalesProductCollapse .ant-collapse-content-box {
  background-color: white !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.BundleCollapseSales .ant-collapse-header {
  border-top: 0 !important;
  border-right: 0 !important;
  border-left: 0 !important;
  border-radius: 8px 8px 0px 0px !important;
  border-bottom: 0.75px solid var(--neutral-background-text-white-300, #e6e6e6) !important;
  background: var(--neutral-background-text-white-100, #f9f9f9) !important;
  padding: 12px 0px !important;
}

.salesCollapse .ant-collapse-content-box {
  background-color: white !important;
}

.salesCollapse .ant-collapse-header {
  padding: 1vh 1vw !important;
}

.shipmentFormHeader {
  color: var(--neutral-background-text-black-100, #666);

  /* M3/Body/Large */
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: 0.5px;
}

.summaryValue {
  color: var(--neutral-background-text-black-300, #404040);

  /* M3/Body/Large */
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: 0.5px;
}

.shipmentFormInputHeader {
  color: var(--neutral-background-text-black-100, #666);

  /* M3/Body/Large */
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: 0.5px;
}

.ListViewSales::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

.ListViewSales::-webkit-scrollbar-track {
  background-color: #f0f0f0; /* Grey shade for track */
}

.ListViewSales::-webkit-scrollbar-thumb {
  background-color: #0c25f3; /* Blue color for thumb */
  border-radius: 5px; /* Rounded corners for thumb */
}

/* On hover, adjust the thumb color */
.ListViewSales::-webkit-scrollbar-thumb:hover {
  background-color: #0c25f3; /* Darker blue on hover */
}

/* On active (click and drag), adjust the thumb color */
.ListViewSales::-webkit-scrollbar-thumb:active {
  background-color: #0c25f3; /* Even darker blue on active */
}

@media (min-width: 1600px) {
  .tableHeader3XL {
    margin-left: 0vw;
  }
  .tableHeaderQuality {
    margin-left: 0vw;
  }
}

@media (max-width: 1590px) {
  .tableHeader3XL {
    margin-left: -1.5vw;
  }
}
