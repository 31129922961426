div.heroHeaderContainer {
  border-radius: 12px;
  border: 1px solid #c6c6c6;
  padding-top: 2vh;
  padding-bottom: 2vh;
  background-image: url(https://lh3.googleusercontent.com/CtLhx1uKvzh777hth85b47WDSnek8DkeZZ0jhBhB3AJnSq2ULGy0iUiFocoXvuYCXVpPmv0s9RRO9WqbYbhvDzh2dfneKVILH1mgVthQLCHFJgRyjr6WuXkGEZwI9tBuAp7mFko);
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
}
