* {
  margin: 0;
  padding: 0;
}

#Avatar {
  margin-left: 20px;
  margin-right: 15px;
}

/* Filter */

.ant-layout {
  background-color: transparent;
}

.filterHeader {
  display: flex;
  justify-content: space-between;

  align-items: center;
}

.SiderNav {
  padding: 3vh 1.5vw;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;

  letter-spacing: 0.005em;
}

.filterName {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;

  letter-spacing: 0.005em;

  color: #757676;
}

.filterValueInner {
  margin: 2.5vh 0;
  display: flex;
  align-items: center;
  justify-content: left;
}

.SiderNav::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

.SiderNav::-webkit-scrollbar-track {
  background-color: #f0f0f0; /* Grey shade for track */
}

.SiderNav::-webkit-scrollbar-thumb {
  background-color: #0c25f3; /* Blue color for thumb */
  border-radius: 5px; /* Rounded corners for thumb */
}

/* On hover, adjust the thumb color */
.SiderNav::-webkit-scrollbar-thumb:hover {
  background-color: #0c25f3; /* Darker blue on hover */
}

/* On active (click and drag), adjust the thumb color */
.SiderNav::-webkit-scrollbar-thumb:active {
  background-color: #0c25f3; /* Even darker blue on active */
}

/* Search profile */
.searchHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.searchHeader p {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 100%;

  letter-spacing: 0.005em;

  color: #545454;
}

.searchBar {
  background-color: #eef4fd;
  width: 55vw;
  margin: 1vh 0;
  padding: 1.5vh 1vw;
}
.ant-input {
  background-color: #eef4fd;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 100%;

  letter-spacing: 0.005em;

  color: #a2a3a5;
}

.TableColumn {
  display: flex;
  justify-content: left;
  align-items: center;
}

.TableColumn1 {
  width: 100%;
  margin-left: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.CustomerData::-webkit-scrollbar {
  width: 0px;
  background-color: transparent;
}

.tableHeader {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 100%;
  letter-spacing: 0.005em;
  color: rgb(101, 101, 101) !important;
  margin-bottom: 24px;
}

#customerName {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;

  letter-spacing: 0.005em;

  color: #0066ff;
}

.tableValue {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 100%;

  letter-spacing: 0.005em;

  color: rgb(101, 101, 101);
}

.Header {
  font-family: "Open Sans", sans-serif;
  font-style: italic;
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;

  letter-spacing: 0.005em;

  color: #a2a3a5;
}

.subHeader {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 100%;

  letter-spacing: 0.005em;

  color: #a2a3a5;
}

.Body {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;

  letter-spacing: 0.005em;

  color: #545454;
}

.popUpClass {
  display: flex;
  justify-content: center;
  align-items: center;
}

.innerPopUp {
  width: 80vw;
  max-height: 60vh;
  padding: 2vh 2vw;
  border-radius: 24px;
  background: #ffffff;
  box-shadow: -3px 4px 13px rgba(0, 0, 0, 0.25);
  /* overflow-y: auto; */
}

.tableDiv {
  max-height: 37vh;
  overflow-y: auto;
}

.tableDiv::-webkit-scrollbar {
  width: 0px;
  background-color: transparent;
}

#popUpHeader {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;
  /* identical to box height, or 16px */

  letter-spacing: 0.005em;

  color: #a2a3a5;
}

#dropDown {
  font-family: "Open Sans", sans-serif;
  background: #ffffff;
  border: 1px solid #d3d3d3;
  border-radius: 12px;
  width: 100%;
  padding: 2vh 1vw;
  color: black;
  appearance: none;
  background-image: url("./Image/down-arrow.png");
  background-position: right center;
  background-repeat: no-repeat;
  background-size: 19px;
  padding-right: 20px;
  margin-bottom: 2vh;
}

.CollapseSubHeader {
  color: var(--neutral-background-text-black-100, #666);
  text-align: center;

  /* Paragraph/P3/Regular */
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 21.6px; /* 120% */
}

.inputHeader {
  color: var(--neutral-background-text-black-100, #666);
  margin-left: 35vw;
  /* M3/Title/Medium */
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  letter-spacing: 0.15px;
}

.css-ptiqhd-MuiSvgIcon-root {
  display: none !important;
}

.inputHeaderEdit {
  color: var(--neutral-background-text-black-100, #666);
  margin-left: 34vw;
  /* M3/Title/Medium */
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  letter-spacing: 0.15px;
}

.inputHeaderBussinessName {
  color: var(--neutral-background-text-black-100, #666);
  /* M3/Title/Medium */
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  letter-spacing: 0.15px;
}
.additionalContactBtn {
  color: var(--primary-blue-500, #0c25f3);
  text-align: center;

  /* M3/Label/Large */
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.1px;
}

.ant-table-thead .ant-table-cell {
  background-color: #f4f9ff;
  font-family: "Open Sans", sans-serif !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 120% !important;

  letter-spacing: 0.005em !important;

  color: #a2a3a5;
}

.ant-table-tbody .ant-table-row {
  background-color: #f4f9ff;
}

.ant-table table {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;

  letter-spacing: 0.005em;

  color: #a2a3a5;
}

.customerBlueDiv {
  border-radius: 9px;
  background-color: #f4f9ff;
  padding: 2vh 1vw 0vh 1vw;
}

.addCustomerHeader {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.inputFieldAddCustomer {
  border-color: #a2a3a5 !important;
  border-top: 0 !important;
  border-right: 0;
  border-left: 0;
  border-radius: 0;
  background-color: #f4f9ff;
  margin: 0;
  padding: 5px 0 6px 0;
  min-width: 200px;
  width: auto;
}

.ant-select-selector {
  border-color: #a2a3a5 !important;
  border-top: 0 !important;
  border-right: 0 !important;
  border-left: 0 !important;
  border-radius: 0 !important;
  background-color: #f4f9ff !important;
  margin: 0 !important;
  padding: 1px 0 1px 0 !important;
  /* width:160px !important; */
}

.ant-select-selection-item {
  color: #545454;
}

.ant-select-arrow {
  color: #0066ff !important;
}

.ant-picker {
  border-color: #a2a3a5 !important;
  border-top: 0 !important;
  border-right: 0 !important;
  border-left: 0 !important;
  border-radius: 0 !important;
  background-color: #f4f9ff !important;
  margin: 0 !important;
  padding: 5px 0 5px 0 !important;
}

.ant-picker-suffix {
  color: #0066ff !important;
}

.ant-collapse-content-box {
  background-color: #f4f9ff !important;
}

.collapseHeader .ant-collapse-header {
  padding: 0 !important;
}

.userBody::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

.userBody::-webkit-scrollbar-track {
  background-color: #f0f0f0; /* Grey shade for track */
}

.userBody::-webkit-scrollbar-thumb {
  background-color: #0c25f3; /* Blue color for thumb */
  border-radius: 5px; /* Rounded corners for thumb */
}

/* On hover, adjust the thumb color */
.userBody::-webkit-scrollbar-thumb:hover {
  background-color: #0c25f3; /* Darker blue on hover */
}

/* On active (click and drag), adjust the thumb color */
.userBody::-webkit-scrollbar-thumb:active {
  background-color: #0c25f3; /* Even darker blue on active */
}

.collapseHeader .ant-collapse-item .ant-collapse-no-arrow {
  margin-bottom: 24px;
}
