.filterHeading {
  font-family: Roboto;
  margin-bottom: 2vh;
  font-size: 19px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: var(--neutral-background-text-black-300, #404040);
}

.productNameHeader {
  color: #545454;
  text-align: center;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 137.5% */
  letter-spacing: -0.408px;
}

.productNameSubHeader {
  color: #545454;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 14px */
  letter-spacing: 0.07px;
}

.MobileListView {
  width: 97% !important;
  overflow-y: auto;
  height: 90% !important;
  padding-right: 1vw;
  margin-left: 2vw;
  margin-right: 2vw;
  margin-top: 3vh;
}

.MobileListViewItem {
  border-radius: 8px !important;
  border-bottom: 0.75px solid var(--neutral-background-text-white-300, #e6e6e6) !important;
  background: var(--neutral-background-text-white-100, #f9f9f9) !important;
  margin-bottom: 2vh !important;
}

.detailsButton {
  border-radius: 16px !important;
  background: #d6e7ff !important;
  color: #06f !important;
  text-align: center !important;
  font-family: Open Sans !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  text-transform: capitalize !important;
}

.detailsButton:hover {
  color: white !important;
  background-color: #06f !important;
}

.blueBoxInventoryProd {
  border-radius: 12px;
  background: #eef4fd;
  padding: 12px 16px;
  margin: 2vh 4vw;
}

.blueBoxHeaders {
  color: #a2a3a5;
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.blueBoxContent {
  color: #a2a3a5;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 0;
}

.numberOfBlocks {
  color: #545454;
  text-align: right;
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.bundlePreviewCard {
  color: #545454;
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0;
}

.bundleMeta .ant-card-body {
  padding: 2vh 2vw 0vh 2vw !important;
}

.bundleNameHeader {
  color: #545454;
  text-align: center;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 137.5% */
  letter-spacing: -0.408px;
}

.blueBoxInventoryBundle {
  border-radius: 12px;
  background: #eef4fd;
  padding: 12px 16px;
  margin: 2vh 4vw;
}

.BundleNumberHeader {
  color: #545454;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.slabRow {
  border-radius: 8px !important;
  border-bottom: 0.75px solid var(--neutral-background-text-white-300, #e6e6e6) !important;
  background: var(--neutral-background-text-white-100, #f9f9f9) !important;
  margin-bottom: 2vh !important;
  padding: 1vh 0;
}

.slabColHeader {
  color: #545454;
  font-family: Open Sans;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.slabColValue {
  color: #545454;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 16px */
  letter-spacing: 0.08px;
}

.productNameInBundleView {
  color: #545454;
  font-family: Open Sans;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.SlabNumberHeader {
  color: #545454;
  font-family: Open Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.slabPropHeader {
  color: #535353;
  font-family: Open Sans;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.slabPropValue {
  color: #434343;
  font-family: Open Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.blueBoxSlab {
  border-radius: 12px;
  background: #eef4fd;
  padding: 2vh 0;
  margin: 1vw 1vw !important;
}

.SlabNotes {
  color: var(--m-3-sys-light-on-primary, #fff) !important;
  text-align: center !important;

  /* M3/label/large */
  font-family: Roboto !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 20px !important; /* 142.857% */
  letter-spacing: 0.1px !important;
  border-radius: 100px !important;
  background: var(--m-3-sys-light-primary, #2c3fff) !important;
}

.SlabNotes:hover {
  color: var(--m-3-sys-light-primary, #2c3fff) !important;
  text-align: center !important;

  /* M3/Label/Large */
  font-family: Roboto !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 20px !important; /* 142.857% */
  letter-spacing: 0.1px !important;
  border-radius: 20px !important;
  background-color: white !important;
}

.SlabEditConfirm {
  color: var(--m-3-sys-light-on-primary, #fff) !important;
  text-align: center !important;

  /* M3/label/large */
  font-family: Roboto !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 20px !important; /* 142.857% */
  letter-spacing: 0.1px !important;
  border-radius: 100px !important;
  background: green !important;
}

.SlabEditCancel {
  color: var(--m-3-sys-light-on-primary, #fff) !important;
  text-align: center !important;

  /* M3/label/large */
  font-family: Roboto !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 20px !important; /* 142.857% */
  letter-spacing: 0.1px !important;
  border-radius: 100px !important;
  background: red !important;
}

.addImageBtn {
  border-radius: 7px !important;
  background: #2c3fff !important;
  color: white !important;
  text-align: center !important;
  font-family: Open Sans !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  text-transform: capitalize !important;
  margin-bottom: 1vh !important;
}

.addImageBtn:hover {
  color: #2c3fff !important;
  background-color: white !important;
}

.stepperName .css-1hv8oq8-MuiStepLabel-label {
  font-size: 2vh !important;
}

.list-headers-style {
  width: "68vw";
  display: "flex";
  justify-content: "space-between";
  align-items: "center";
  padding-left: "18px";
  color: #a2a3a5;
}

.borderless-button {
  border: none;
}

.inventoryContent::-webkit-scrollbar {
  width: 0px;
  background-color: transparent;
}

.imagePopUp .ant-card-body {
  padding: 0;
  background-color: #1f7eff;
}

.bundle-values {
  color: black;
}

.bundle-table .ant-table-thead .ant-table-cell {
  color: black !important;
}

.values {
  color: black !important;
}

.form4Header {
  color: rgba(57, 57, 57, 0.66);
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.14px;
}

.addImageTable .ant-collapse-content-box {
  padding: 0 !important;
}

.UploadDescription {
  color: #a2a3a5;
  font-family: Open Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: 0.09px;
}

.imageUploadHeading {
  color: #06f;
  font-family: Open Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: 0.09px;
}

.deleteIconCls {
  color: red;
  font-size: medium;
}

.deleteIconCls:hover {
  color: black;
}

.cloudUploadCls {
  color: #017fff;
  font-size: large;
}

.cloudUploadCls:hover {
  color: black;
}

.offerOptionCard {
  position: fixed;
  width: 150px;
  bottom: 74px;
  right: 64px;
  box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15),
    0px 1px 2px 0px rgba(0, 0, 0, 0.3);
}

.offerOptionCard > div.ant-card-body {
  padding: 20px 0px;
}

.offerOptionCard button {
  width: 100%;
}
