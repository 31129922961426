* {
  margin: 0;
  padding: 0;
}

.requiredInput label {
  color: red !important;
}

.ant-layout-header {
  background-color: #f5f5f2;
  height: auto;
  color: #545454;
}

.searchHeader p {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 100%;

  letter-spacing: 0.005em;

  color: #545454;
}

.customerBlueDiv {
  border-radius: 9px;
  background-color: #f4f9ff;
  padding: 3vh 1vw 3vh 1vw;
}

.userTableHeader {
  color: var(--neutral-background-text-black-400, #2d2d2d);

  /* M3/Label/Large */
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.1px;
  margin-bottom: 1vh;
  margin-top: 1vh;
}

.userCollapseRow {
  color: var(--neutral-background-text-black-300, #404040);

  /* M3/Body/Large */
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: 0.5px;
}

.collapseHeaderUser {
  border-radius: 8px;
  border-bottom: 0.75px solid var(--neutral-background-text-white-300, #e6e6e6);
  background: var(--neutral-background-text-white-100, #f9f9f9);
  border-top: 0;
  border-left: 0;
  border-right: 0;
}

.collapseHeaderUser .ant-collapse-header {
  padding: 1vh 1vw !important;
}

.collapseHeaderUser .ant-collapse-content-box {
  background-color: white !important;
  border: 0.75px solid var(--neutral-background-text-white-300, #e6e6e6);
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.options {
  padding: 8px;
}

.addEmployeeProfile {
  color: var(--neutral-background-text-black-300, #404040);
  text-align: center;

  /* Subheading/Semi Bold */
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 120% */
  margin-bottom: 6vh;
}
