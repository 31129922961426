.searchNav {
  background: var(--neutral-background-text-white-100, #f9f9f9);
  border-radius: 8px;

  /* Elevation/Light Mode/1 */
  box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15),
    0px 1px 2px 0px rgba(0, 0, 0, 0.3);
  padding-left: 5px;
  padding-right: 5px;
}

.searchNavText {
  color: var(--neutral-background-text-black-300, #404040);
  text-align: center;

  /* M3/Label/Large */
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.1px;
}

.searchNavTextActive {
  color: var(--neutral-background-text-black-300, #404040);
  text-align: center;

  /* M3/Label/Large */
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.1px;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 3px solid var(--m-3-sys-light-primary, #2c3fff);
}

.ListViewSearch {
  width: 83vw !important;
  overflow-y: auto;
  height: 67vh !important;
  padding-right: 1vw;
}

.ListViewSearch::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

.ListViewSearch::-webkit-scrollbar-track {
  background-color: #f0f0f0; /* Grey shade for track */
}

.ListViewSearch::-webkit-scrollbar-thumb {
  background-color: #0c25f3; /* Blue color for thumb */
  border-radius: 5px; /* Rounded corners for thumb */
}

/* On hover, adjust the thumb color */
.ListViewSearch::-webkit-scrollbar-thumb:hover {
  background-color: #0c25f3; /* Darker blue on hover */
}

/* On active (click and drag), adjust the thumb color */
.ListViewSearch::-webkit-scrollbar-thumb:active {
  background-color: #0c25f3; /* Even darker blue on active */
}
