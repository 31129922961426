.FilterHeaders {
  color: var(--neutral-background-text-black-300, #404040);

  /* Paragraph/P2/Semi Bold */
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 19px; /* 118.75% */
  display: flex;
  align-items: center;
  justify-content: left;
  margin-bottom: 1.5vh;
  margin-left: 0.5vw;
}

.FilterVals {
  color: var(--neutral-background-text-black-200, #535353);

  /* M3/Body/Large */
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: 0.5px;
}

.FilterName {
  color: var(--neutral-background-text-black-300, #404040);

  /* Paragraph/P2/Semi Bold */
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 19px; /* 118.75% */
  margin-bottom: 1vh;
}
