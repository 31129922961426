.mainHeading {
  color: var(--primary-blue-500, #0c25f3) !important;
  text-align: center !important;
  font-family: "Montserrat", sans-serif;
  font-size: 1.5rem !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 20px !important; /* 83.333% */
  letter-spacing: 0.024px !important;
  text-transform: uppercase;
  margin: 0 !important;
}
.primaryLayout {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  padding: 1vh 0vw;
  align-items: center;
  /* gap: 24px; */
  margin: 0 !important;
}

.searchBar {
  border-radius: 28px !important;
  background: var(--primary-blue-50, #f0f1fe) !important;
  /* width: 100% !important; */
  margin: 0 0.5vw 0 0.5vw !important;
  width: 55vw;
  padding: 1.5vh 1vw;
}
.searchBarVisible {
  border-radius: 28px !important;
  background: var(--neutral-blue-100, #e7eaee) !important;
  /* width: 100% !important; */
  margin: 0 0.5vw 0 0.5vw !important;
  width: 55vw;
  padding: 1.5vh 1vw;
}

.cartButton {
  color: var(--m-3-sys-light-primary, #2c3fff);
  text-align: center;

  /* M3/Label/Large */
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.1px;
  border-radius: 20px !important;
}

.cartButton:hover {
  color: var(--m-3-sys-light-primary, #2c3fff) !important;
  border-color: var(--m-3-sys-light-primary, #2c3fff) !important;
}

.searchBarText {
  color: var(--m-3-sys-light-on-surface-variant, #46464f);

  /* M3/Body/Large */
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: 0.5px;
}

.searchBarTextVisible .ant-input {
  color: var(--neutral-background-text-black-300, #404040) !important;

  /* M3/Body/Large */
  font-family: Roboto !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 24px !important; /* 150% */
  letter-spacing: 0.5px !important;
  background: var(--neutral-blue-100, #e7eaee) !important;
}

.primaryNav {
  background: var(--m-3-sys-light-surface, #f7faf8);
  border-top: 4px solid #f1f1ef;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.notActive {
  color: var(--neutral-background-text-black-300, #404040) !important;
  text-align: center !important;

  /* M3/Body/Large */
  font-family: Roboto !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 24px !important; /* 150% */
  letter-spacing: 0.5px !important;
}

.active {
  color: var(--m-3-sys-light-primary, #0c25f3) !important;
  text-align: center !important;

  /* M3/Body/Large */
  font-family: Roboto !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 24px !important; /* 150% */
  letter-spacing: 0.5px !important;
}

.notActiveIcon {
  color: var(--neutral-background-text-black-300, #404040) !important;
  text-align: center !important;

  /* M3/Body/Large */
  font-family: Roboto !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 24px !important; /* 150% */
  letter-spacing: 0.5px !important;
}

.activeIcon {
  color: var(--m-3-sys-light-primary, #0c25f3) !important;
  text-align: center !important;

  /* M3/Body/Large */
  font-family: Roboto !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 24px !important; /* 150% */
  letter-spacing: 0.5px !important;
}

.navContent:hover {
  .notActiveIcon {
    color: var(--m-3-sys-light-primary, #0c25f3) !important;
  }
  .notActive {
    color: var(--m-3-sys-light-primary, #0c25f3) !important;
  }
}

.ant-tabs-nav-list {
  width: 100%;
  display: flex !important;
  justify-content: space-evenly !important;
}

.ant-tabs-tab-btn:hover {
  color: var(--m-3-sys-light-primary, #0c25f3) !important;
}

.ant-tabs-tab:hover {
  color: var(--m-3-sys-light-primary, #0c25f3) !important;
}

.avatar-hover:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Add your desired shadow styles here */
  transition: box-shadow 0.3s ease; /* Add a smooth transition for the hover effect */
}

.contentPosition {
  position: absolute;
  z-index: 3;
  border-radius: 0px 0px 28px 28px;
  background: var(--neutral-blue-100, #e7eaee);
}

.messageBtnIcon {
  font-size: x-large;
  color: #2c3fff;
}

.messageBtnIcon:hover {
  color: grey;
  cursor: pointer;
}
