.mobileNavLayout {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 1vh 0vw;
  align-items: center;
  /* gap: 24px; */
  margin: 0 !important;
}

.mobileNavHeading {
  color: var(--primary-blue-500, #0c25f3) !important;
  text-align: center !important;
  font-family: "Montserrat", sans-serif;
  font-size: 1rem !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 20px !important; /* 83.333% */
  letter-spacing: 0.024px !important;
  text-transform: uppercase;
  margin-left: -2vw !important;
  margin-bottom: 0 !important ;
}

.mobileCartButton {
  color: var(--m-3-sys-light-primary, #2c3fff);
  text-align: center;

  /* M3/Label/Large */
  font-family: Roboto;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.1px;
  border-radius: 20px !important;
}

.mobileCartButton:hover {
  color: var(--m-3-sys-light-primary, #2c3fff) !important;
  border-color: var(--m-3-sys-light-primary, #2c3fff) !important;
}

.searchDialog .css-bdhsul-MuiTypography-root-MuiDialogTitle-root {
  background-color: #e7eaee !important;
  padding: 0 !important;
}

.searchBarVisibleMobile {
  background: var(--neutral-blue-100, #e7eaee) !important;
  /* width: 100% !important; */
  padding: 1.5vh 3vw;
}

.searchBarMobile {
  background: var(--primary-blue-50, #f0f1fe) !important;
  /* width: 100% !important; */
  padding: 1.5vh 3vw;
}
