.sellerHeaders {
  border-radius: 12px;
  border: 1px solid var(--neutral-background-text-white-500, #c6c6c6);
  padding-top: 2vh !important;
  padding-bottom: 2vh !important;
  background-image: url("https://lh3.googleusercontent.com/CtLhx1uKvzh777hth85b47WDSnek8DkeZZ0jhBhB3AJnSq2ULGy0iUiFocoXvuYCXVpPmv0s9RRO9WqbYbhvDzh2dfneKVILH1mgVthQLCHFJgRyjr6WuXkGEZwI9tBuAp7mFko");
  background-repeat: no-repeat;
  background-size: cover;
}

.cardHeader {
  color: var(--neutral-background-text-black-300, #404040);

  /* M3/body/large */
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: 0.5px;
}

.cardSubHeader {
  color: var(--neutral-background-text-black-300, #404040);

  /* M3/Body/Medium */
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.25px;
}
