.mobileAnalyticsMainHeader {
  text-align: center;
  font-size: 25px;
  font-style: normal;
  font-weight: 2000;
  font-family: Montserrat;
  /* line-height: 19px; 118.75% */
  letter-spacing: -0.32px;
  background: linear-gradient(
    90deg,
    #000,
    #333,
    #666
  ); /* Gradient background from black to dark grey */
  -webkit-background-clip: text; /* Clip text to the gradient background */
  background-clip: text;
  color: transparent; /* Make the text transparent to show the background gradient */
  display: inline-block;
}
