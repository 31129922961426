div.cardBorder {
  border: 1px solid #eaeaea;
}

div.productCard {
  :global(div.ant-card-head) {
    border-bottom: 1px solid #ddd;
  }
  &:not(:first-child) {
    margin-top: 20px;
  }
}

div.bundleCard {
  &:not(:first-child) {
    margin-top: 20px;
  }
}

div.bundleInnerSection {
  display: flex;

  div.bundleImageContainer {
    width: 40%;
  }

  div.bundleSlabsInfoContainer {
    width: 60%;
    height: 44vh;
    overflow-y: auto;
    border: 1px solid #d3d3d3;

    &::-webkit-scrollbar {
      width: 8px; /* Width of the scrollbar */
    }

    &::-webkit-scrollbar-track {
      background-color: #f0f0f0; /* Grey shade for track */
    }

    &::-webkit-scrollbar-thumb {
      background-color: #0c25f3; /* Blue color for thumb */
      border-radius: 5px; /* Rounded corners for thumb */
    }

    /* On hover, adjust the thumb color */
    &::-webkit-scrollbar-thumb:hover {
      background-color: #0c25f3; /* Darker blue on hover */
    }

    /* On active (click and drag), adjust the thumb color */
    &::-webkit-scrollbar-thumb:active {
      background-color: #0c25f3; /* Even darker blue on active */
    }
  }
}
