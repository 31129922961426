.slick-active button {
  color: black !important;
  background: black !important ;
}

.slick-active button::after {
  color: black !important;
}

.slick-dots li {
  color: black !important;
  background: rgb(110, 110, 110) !important ;
}

.imageCarouselDiv {
  height: 44vh !important;
  object-fit: cover;
}
.slick-slider {
  width: 100% !important;
}

.subHeading {
  /* font-size: 16px; */
  font-family: Open Sans;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.16px;
  text-transform: capitalize;
}

.imageHeading {
  color: rgba(57, 57, 57, 0.66);
  font-size: 18px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.18px;
}

.colHeading {
  color: rgba(57, 57, 57, 0.66);
  font-size: 14px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.14px;
}

.bundleRow {
  border-radius: 8px 8px 0px 0px;
  border-bottom: 0.75px solid var(--neutral-background-text-white-300, #e6e6e6);
  background: var(--neutral-background-text-white-100, #f9f9f9);
}

.valueStyle1 {
  color: var(--neutral-background-text-black-300, #404040);

  /* M3/Body/Large */
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: 0.5px;
}

.messageKey {
  color: #000;
  font-size: 14px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.14px;
}

.messageTo {
  color: #000;
  font-size: 14px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.14px;
}

.tNC {
  color: #000 !important;
  font-size: 14px !important;
  font-family: Open Sans !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  letter-spacing: 0.14px !important;
}

.sellerViewHeader {
  border: 0.5px solid rgba(57, 57, 57, 0.66);
  background: #e4e4e4;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.enterpriseName {
  color: #000;
  font-size: 5vw;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 900;
  line-height: 100%;
  letter-spacing: 0.09px;
  text-transform: uppercase;
}

.subDetails {
  color: #000;
  font-size: 1.5vw;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: 0.07px;
  text-transform: uppercase;
}

.subDetails1 {
  font-size: 14px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: 0.07px;
}
.componentHeader {
  color: #000;
  font-size: 18px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: 0.09px;
  text-transform: uppercase;
}

.afterClick {
  background-color: #fafaf7;
}

.beforeClick {
  background-color: #f3f9fd;
}

.supplierBtn {
  color: var(--primary-blue-500, #0c25f3);

  /* M3/Body/Large */
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: 0.5px;
}

.contactSupplierBtn {
  color: var(--m-3-sys-light-on-primary, #fff);
  text-align: center;

  /* M3/label/large */
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.1px;
  border-radius: 100px;
  background: var(--m-3-sys-light-primary, #2c3fff);
}

.contactSupplierBtn:hover {
  color: var(--m-3-sys-light-primary, #2c3fff) !important;
  text-align: center;

  /* M3/Label/Large */
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.1px;
  border-radius: 20px !important;
  background-color: white !important;
}

.colHeadingMarket {
  color: var(--neutral-background-text-black-400, #2d2d2d);

  /* M3/Label/Large */
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.1px;
}

.marketPlaceTable .ant-table-thead > tr > th {
  background: var(--primary-blue-50, #f0f1fe) !important;
  color: var(--neutral-background-text-black-300, #404040) !important;
  text-align: center !important;

  /* M3/Label/Large */
  font-family: Roboto !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 20px !important; /* 142.857% */
  letter-spacing: 0.1px !important;
  padding-top: 2vh !important;
  padding-bottom: 2vh !important;
}

.values {
  color: var(--neutral-background-text-black-300, #404040) !important;
  text-align: center;

  /* M3/Body/Medium */
  font-family: Roboto !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 20px !important; /* 142.857% */
  letter-spacing: 0.25px !important;
  background-color: white !important;
  padding-top: 2vh !important;
  padding-bottom: 2vh !important;
}
